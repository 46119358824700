<template>
  <div class="mb-6" v-if="visible">
    <label
      class="preliminary-custom-label mb-4 is-flex is-align-items-center is-justify-content-space-between"
      :data-identifier="field.identifier"
    >
      {{ field.label }}
      <b-icon
        v-if="error"
        icon-pack="fa"
        icon="exclamation-circle"
        type="is-danger"
        size="is-small"
        class="ml-2"
      ></b-icon>
    </label
    >

    <div
      class="question-container"
      :class="{
        'question-container--two-columns': field.inputValues.length <= 2,
      }"
    >
      <div
        v-for="(inputValue, index) in field.inputValues"
        :id="`prelimInput_${field.identifier}_${index}`"
        :key="field.identifier + index"
        @click="setSelection(inputValue)"
      >
        <!--Icon style-->
        <div
          v-if="inputValue.labelIcon"
          class="answer-tile has-text-grey-icon cursor-pointer"
          :class="{ 'answer-tile--active': selectedValue === inputValue.value }"
        >
          <b-icon
            icon-pack="fa"
            :icon="inputValue.labelIcon"
            type="is-grey-icon"
            size="is-large"
            class="mb-1"
          ></b-icon>

          <br />

          <span>{{ inputValue.text }}</span>
        </div>

        <!--Radio style-->
        <div
          v-else
          class="answer-tile has-text-grey-icon cursor-pointer"
          :class="{ 'answer-tile--active': selectedValue === inputValue.value }"
        >
          <span>{{ inputValue.text }}</span>
        </div>
      </div>
    </div>
    
    <div v-if="error" class="field-error-message">
      <p>{{ error }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "PreliminaryRadioQuestion",
  props: {
    field: {
      type: Object,
      required: true,
    },
    visible: {
      type: Boolean,
      required: true,
    },
    mortgage: {
      type: Boolean,
      required: false,
      default: false,
    },
    error: {
      type: String,
      required: false,
    },
    validated: {
      type: Boolean,
      required: false,
    },
    valid: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      selectedValue: null,
    };
  },
  mounted() {
    this.selectedValue =
      this.$store.getters[
        this.mortgage
          ? "mortgagePreliminaryAssessment/answer"
          : "preliminaryAssessment/answer"
      ](this.field.identifier) || null;
    this.$watch("selectedValue", this.valueWatcher);
  },
  methods: {
    valueWatcher() {
      this.$store.dispatch(
        this.mortgage
          ? "mortgagePreliminaryAssessment/addData"
          : "preliminaryAssessment/addData",
        {
          question: this.field.identifier,
          answer: this.selectedValue,
        }
      );
    },
    setSelection(inputValue) {
      this.selectedValue = inputValue.value;
      this.$emit("input", inputValue.value);
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../assets/scss/colors.scss";

label.preliminary-custom-label {
  color: #363636;
  font-weight: 700;
  display: block;
}

.question-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1rem;

  &--two-columns {
    grid-template-columns: repeat(2, 1fr);
  }

  .answer-tile {
    border: 1px solid #dfe6ef;
    border-radius: 1rem;
    padding: 0.7rem;
    text-align: center;
    font-size: 0.75rem;
    overflow: hidden;
    word-break: break-word;
    height: 100%;
    transition: all 0.1s;

    &--active {
      border: 1px solid $blue !important;
      color: $blue !important;
    }

    .icon {
      font-size: 1.2rem;
      color: inherit !important;
    }
  }

  .answer-tile__radio {
    border: 2px solid #dfe6ef;
    border-radius: 1rem;
    padding: 2rem 0.7rem;
    overflow: hidden;
    word-break: break-word;
    height: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    &--active {
      border: 1px solid $blue !important;
      color: $blue !important;
    }

    .radio:hover {
      color: inherit;
    }
  }
}

.field-error-message {
  color: #f14668;
  margin-top: 8px;
  font-size: 12px;
  position: absolute;
}

@media only screen and (min-width: 769px) {
  .answer-tile {
    padding: 2rem !important;
  }

  .question-container {
    grid-template-columns: repeat(2, 1fr);

    &--two-columns {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}

.mbh {
  .answer-tile,
  .answer-tile__radio {
    border: 2px solid white;
    background: white;
    border-radius: 32px;
    font-size: 14px;

    &--active {
      border: 2px solid #0c4fad !important;
      color: #0c4fad !important;
    }
  }
}
</style>
